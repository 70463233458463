<script lang="ts" setup>
import { ref, computed } from "vue";
import UsersGrid from "@/components/iam/UsersGrid.vue";
import AccessGrid from "@/components/iam/AccessGrid.vue";
import RelationshipSelector from "@/components/iam/RelationshipSelector.vue";
import { iamType } from "@/services/api/iam.api";

const emits = defineEmits(["add-relationship", "remove-relationship"]);

const props = defineProps({
  viaAccess: undefined,
  refreshKey: undefined,
});

const currentType = iamType.User;

const selectorVisible = ref(false);

const accessViaUserGroup = computed(() => props.viaAccess.domain == iamType.UserGroup);

const preFilter = computed(() => {
  return [
    {
      fieldName: "usergroups:id",
      exact: [props.viaAccess.id],
    },
  ];
});

function onAddRelationship(selection) {
  emits("add-relationship", selection);
}

async function onRemoveRelationship(id) {
  emits("remove-relationship", id);
}
</script>

<template>
  <div>
    <users-grid v-if="accessViaUserGroup" :pre-filter="preFilter" allow-remove="true" @remove-relationship="onRemoveRelationship" :refreshKey="props.refreshKey">
      <template #add-relation>
        <v-btn dark class="primary mt-4" @click.stop="selectorVisible = true">
          <v-icon dark center>mdi-playlist-plus</v-icon>
        </v-btn>
      </template>
    </users-grid>
    <access-grid v-else allow-remove="true" @remove-relationship="onRemoveRelationship" :viaAccess="viaAccess" :currentType="currentType" :refreshKey="props.refreshKey">
      <template #add-relation>
        <v-btn dark class="primary mt-4" @click.stop="selectorVisible = true">
          <v-icon dark center>mdi-playlist-plus</v-icon>
        </v-btn>
      </template>
    </access-grid>
    <relationship-selector v-model="selectorVisible" @add-relationship="onAddRelationship" :domain-type="viaAccess.domain" :selection-type="currentType"> </relationship-selector>
  </div>
</template>

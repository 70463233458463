<script lang="ts" setup>
import { ref, computed, getCurrentInstance } from "vue";
import UsersRelationship from "@/components/iam/UsersRelationship.vue";
import { iamType, addRelationship, removeRelationship } from "@/services/api/iam.api";

const props = defineProps({
  resourceId: undefined,
  projectId: undefined,
  roleId: undefined,
});

const refreshKey = ref(0);

const accessSelection = computed(() => {
  let access = {};
  if (props.userId) {
    access = { domain: iamType.User, id: props.userId };
  } else if (props.groupId) {
    access = { domain: iamType.UserGroup, id: props.groupId };
  } else if (props.resourceId) {
    access = { domain: iamType.Resource, id: props.resourceId };
  } else if (props.projectId) {
    access = { domain: iamType.Project, id: props.projectId };
  } else if (props.roleId) {
    access = { domain: iamType.Role, id: props.roleId };
  }
  return access;
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function onAddRelationship(selection) {
  let response = {};
  console.log("AccessUsers, addRelationship ", props.resourceId, props.projectId, props.roleId, selection);
  if (props.resourceId) {
    response = await addRelationship(iamType.Resource, props.resourceId, selection);
  }
  if (props.projectId) {
    response = await addRelationship(iamType.Project, props.projectId, selection);
  }
  if (props.roleId) {
    response = await addRelationship(iamType.Role, props.roleId, selection);
  }

  if (response) {
    if (!response.error) {
      refreshKey.value++;
    } else {
      proxy.$toaster.error("Toewijzen van de gebruikers aan een groep is mislukt! " + response.error);
    }
  }
}

async function onRemoveRelationship(id) {
  let response = {};
  console.log("AccessUsers, removeRelationship ", props.resourceId, props.projectId, props.roleId, id);

  if (props.resourceId) {
    response = await removeRelationship(iamType.Resource, props.resourceId, iamType.User, id);
  }
  if (props.projectId) {
    response = await removeRelationship(iamType.Project, props.projectId, iamType.User, id);
  }
  if (props.roleId) {
    response = await removeRelationship(iamType.Role, props.roleId, iamType.User, id);
  }
  if (response) {
    if (!response.error) {
      refreshKey.value++;
    } else {
      proxy.$toaster.error("Verwijder van de gebruikers aan een groep is mislukt! " + response.error);
    }
  }
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <users-relationship
      @add-relationship="onAddRelationship"
      allow-remove="true"
      @remove-relationship="onRemoveRelationship"
      :refreshKey="refreshKey"
      :viaAccess="accessSelection"
    ></users-relationship>
  </v-container>
</template>
